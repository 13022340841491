.bg-sobre {
  background: url('../assets/bg-home.jpg') center center no-repeat;
  padding: 80px 0;
  background-size: cover;
} 

.container-fluid { max-width: 1600px; }

h2 {
  font-size: 48px;
  color: #b0604f;
  padding: 25px 0;
  font-family: 'Vidaloka', sans-serif;
}

h2 a {
  font-size: 16px;
  font-family: 'Open sans', sans-serif;
  display: block;
  color: #000;
  text-decoration: underline;
}
h2 a:hover {
  color: #ad5548;
}

h2.special {
  border-bottom: 2px solid #828693;
  margin-bottom: 30px;
}

.bg-white { padding-top: 60px; }

h6 {
  color: #c18275;
  font-size: 20px;
  font-weight: 700;
  font-style: italic;
  margin-bottom: 20px;
}

.text-icon {
  padding: 30px;
  font-size: 17px;
  color: #828693;
}

.bg-sobre h2 { padding: 0;}

.bg-certificados {
  text-align: center;
  padding: 60px 0;
  background: url('../assets/bg-home-2.jpg') center center no-repeat;
  background-size: cover;
}

.text-icon img {
  margin-bottom: 30px;
}

.text-icon p {
  font-size: 19px;
  line-height: 26px;
}

.bg-gray {
  background: #606770;
  padding: 60px 0 30px;
}
.bg-gray p {
  color: #fff;
  font-size: 22px;
  line-height: 30px;  
}

.bg-missao {
  padding: 50px;
  margin: 50px;
}

.bg-missao h2 {
  text-transform: uppercase;
  margin: 30px;
}

.bg-missao p {
  padding: 25px;
  font-size: 22px;
  border-left: 1px solid gray;
  line-height: 30px;
  padding-left: 80px;
}

.img-left {
  float: left;
  margin: 0 30px 30px 0;
  width: 250px;
}

.img-right {
  float: right;
  margin: 0 0 30px 30px;
  width: 300px;
}

.andressa-sobre {
  padding: 80px 0;
  background: url(../assets/andressa-coaching.jpg) left bottom no-repeat;
  background-size: contain;
  margin-top: 120px;
}

.andressa-sobre ul li {
  font-size: 19px;
  color: #ad5548;
  line-height: 30px;
  margin-left: 25px;
  font-family: 'Open Sans', sans-serif;
}

.andressa-sobre h4.special-blue {
  font-size: 28px;
  color: #25566a;
  font-weight: 300;
  line-height: 40px;
  margin-top: 40px;
  font-family: 'Vidaloka', sans-serif;
}

.indicacoes {
  border: 2px solid #ffc8b5;
  padding: 50px;
  margin-top: 70px;
}

.indicacoes ul li {
  list-style: none;
  margin: 0;
  font-size: 22px;
  font-family: 'Open Sans', sans-serif;
  color: #5b616c;
  line-height: 24px;
}

.indicacoes ul li img {
  margin-right: 15px;
}

.indicacoes h4 {
  font-size: 40px;
  color: #25566a;
  line-height: 24px;
  margin-bottom: 40px;
  font-family: 'Vidaloka', sans-serif;
}

.bg-dubai {
  padding: 80px 0;
  background: url(../assets/bg-marina-dubai.jpg) right bottom no-repeat;
  background-size: cover;
  background-color: #9cbfca;
}

.bg-dubai h2 {
  font-size: 26px;
  color: #ffffff;
  line-height: 30px;
}

.bg-dubai p {
  font-size: 16px;
  color: #ffffff;
}

.bg-dubai h4 {
  font-size: 24px;
  line-height: 34px;
  color: #25566a;
  padding: 0 40px;
  position: relative;
  margin-top: 70px;
  padding-right: 50%;
  font-weight: 600;
  text-shadow: 0 10px 20px rgb(184 205 216);
}

.bg-dubai h4:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 7px;
  background: #25566a;
}

.andressa-terapia {
  padding: 80px 0;
  background: url(../assets/bg-terapia.jpg) center center no-repeat;
  background-size: cover;
}

.andressa-terapia h2 {
  font-size: 40px;
  line-height: 40px;
}

.andressa-terapia ul {
  list-style: none;
  margin-top: 25px;
}

.andressa-terapia ul li {
  font-size: 19px;
  line-height: 24px;
  color: #484a4f;
  position: relative;
  padding-left: 25px;
  font-weight: 500;
}

.andressa-terapia ul li::before {
  position: absolute;
  content: "";
  width: 10px;
  height: 3px;
  background: #ad5548;
  top: 18px;
  left: 0;
}

.box-red {
  border: 1px solid transparent;
  padding: 30px;
  background: #ffb297;
}

.box-red p {
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
  font-weight: 600;
  margin: 0;
}

.indicacoes ul li {
  position: relative;
  padding-left: 30px;
}

.indicacoes ul li img {
  margin-right: 15px;
  position: absolute;
  top: 8px;
  left: 0;
}

@media (max-width: 768px){
  .andressa-sobre { padding-top: 20px; padding-bottom: 30px; background: none; margin-top: 0; }
  .andressa-sobre ul li { font-size: 18px; }

  .andressa-sobre h4.special-blue {
    font-size: 28px !important;
    line-height: 34px !important;
    margin-top: 30px;
  }

  .indicacoes {
    border: 2px solid #ffc8b5;
    padding: 30px;
    margin-top: 40px;
  }

  .bg-dubai {
    padding-bottom: 150px;
    background: #9cbfca url(../assets/marina-dubai.jpg) right -300px bottom no-repeat;
    background-size: 1300px;
    padding-top: 30px;
  }
  .bg-dubai h4 {
    text-shadow: 0 0 30px rgb(0 0 0 / 40%);
    padding-right: 20%;
    font-size: 21px !important;
  }

  .andressa-terapia { padding: 30px 0; }
}